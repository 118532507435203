import {
  ShippingGroupStatus,
  ShippingGroupType,
  PackageStatus,
  OrderStatus,
  OrderPaymentMethod,
  OrderPaymentStatus,
  TicketStatus,
  ShipmentType,
  ShippingMethod,
} from './types';

import { COUNTRIES_SUPPORTED } from '../config';

export const AUTH_TOKEN = 'auth-token';

export const DEFAULT_PAGE_SIZE = 100;

type _TextAndColor = {
  text: string;
  color: string;
};

type _TextAndHexColor = _TextAndColor & {
  hexColor: string;
};

type _TextAndShortAndColor = _TextAndColor & {
  short: string,
}

export const GROUP_STATUS: { [key in ShippingGroupStatus]: _TextAndColor } = {
  OPEN: { text: '拼团中', color: 'green' },
  CLOSED: { text: '已截团', color: 'default' },
  CANCELLED: { text: '已取消', color: '' },
};

export const GROUP_TYPE: { [key in ShippingGroupType]: _TextAndShortAndColor } = {
  REGULAR: { text: '普货团', short: '普货', color: 'default' },
  FOOD: { text: '敏感团', short: '敏感', color: 'default' },
};

export const SHIPMENT_TYPE: { [key in ShipmentType]: _TextAndColor } = {
  REGULAR: { text: '普货', color: 'blue' },
  FOOD: { text: '敏感', color: 'red' },
  BOTH: { text: '混合', color: 'purple' },
};

export const SHIPPING_METHOD: { [key in ShippingMethod]: string } = {
  AIR: '空运',
  SEA: '海运',
};

export const PACKAGE_STATUS: { [key in PackageStatus]: _TextAndColor } = {
  WAREHOUSE_PENDING: { text: '仓库待收货', color: 'gold' },
  WAREHOUSE_RECEIVED: { text: '已入库', color: 'cyan' },
  ORDER_CREATED: { text: '已下单', color: 'lime' },
  CANCELLED: { text: '已取消', color: '' },
  PROBLEM: { text: '问题件', color: 'red' },
};

export const ORDER_STATUS: { [key in OrderStatus]: _TextAndHexColor } = {
  CREATED: { text: '待打包', color: 'orange', hexColor: '#fa8c16' },
  PACKING: { text: '打包中', color: 'green', hexColor: 'cadetblue' },
  PACKED: { text: '已打包', color: 'blue', hexColor: '#1890ff' },
  SHIPPED: { text: '运输中', color: 'purple', hexColor: '#722ed1' },
  PICKUP_PENDING: { text: '待取货', color: 'magenta', hexColor: '#eb2f96' },
  USER_RECEIVED: { text: '已收货', color: '', hexColor: '' },
  PROBLEM: { text: '问题单', color: 'red', hexColor: '#f5222d' },
  CANCELLED: { text: '已取消', color: '', hexColor: '' },
};

export const DIRECT_ORDER_STATUS: { [key in OrderStatus]?: _TextAndHexColor } = {
  CREATED: { text: '待打包', color: 'orange', hexColor: '#fa8c16' },
  PACKING: { text: '打包中', color: 'green', hexColor: 'cadetblue' },
  PACKED: { text: '已打包', color: 'blue', hexColor: '#1890ff' },
  SHIPPED: { text: '国际运输中', color: 'purple', hexColor: '#722ed1' },
  USER_RECEIVED: { text: '已收货', color: '', hexColor: '' },
  PROBLEM: { text: '问题单', color: 'red', hexColor: '#f5222d' },
  CANCELLED: { text: '已取消', color: '', hexColor: '' },
};

export const GROUP_ORDER_STATUS: { [key in OrderStatus]: _TextAndHexColor } = {
  CREATED: { text: '待打包', color: 'orange', hexColor: '#fa8c16' },
  PACKING: { text: '打包中', color: 'green', hexColor: 'cadetblue' },
  PACKED: { text: '已打包', color: 'blue', hexColor: '#1890ff' },
  SHIPPED: { text: '国际运输中', color: 'purple', hexColor: '#722ed1' },
  PICKUP_PENDING: { text: '待取货', color: 'magenta', hexColor: '#eb2f96' },
  USER_RECEIVED: { text: '已取货', color: '', hexColor: '' },
  PROBLEM: { text: '问题单', color: 'red', hexColor: '#f5222d' },
  CANCELLED: { text: '已取消', color: '', hexColor: '' },
};

export const ORDER_PAYMENT_STATUS: { [key in OrderPaymentStatus]: _TextAndColor } = {
  PAID: { text: '已付款', color: '' },
  UNPAID: { text: '未付款', color: 'red' },
  PARTIALLY_PAID: { text: '部分付款', color: 'volcano' },
};

export const ORDER_PAYMENT_METHOD: { [key in OrderPaymentMethod]: _TextAndColor } = {
  WECHAT_TRANSFER: { text: '微信转账', color: '' },
  ALIPAY_TRANSFER: { text: '支付宝转账', color: '' },
  TAOBAO_ORDER: { text: '淘宝拍单', color: '' },
  BANK_TRANSFER: { text: '银行转账', color: '' },
};

export const TICKET_STATUS: { [key in TicketStatus]: _TextAndHexColor } = {
  OPEN: { text: '未解决', color: 'orange', hexColor: '#fa8c16' },
  AWAITING_CUSTOMER_RESPONSE: { text: '等待回复', color: 'blue', hexColor: '#1890ff' },
  RESOLVED: { text: '已解决', color: 'green', hexColor: 'cadetblue' },
  CLOSED: { text: '已关闭', color: '', hexColor: '' },
};

export const SHIPPING_COMPANY = {
  申通快递: '申通',
  顺丰速运: '顺丰',
  圆通速递: '圆通',
  韵达速递: '韵达',
  中通快递: '中通',
  天天快递: '天天',
  百世快递: '百世',
  京东快递: '京东',
  全峰快递: '全峰',
  优速物流: '优速',
  宅急送: '宅急送',
  德邦快递: '德邦',
  中铁物流: '中铁',
  当当网: '当当',
  中国邮政EMS: 'EMS',
  中国邮政包裹信件: '邮政',
  其他: '其他',
};

export const COUNTRIES = COUNTRIES_SUPPORTED;

export const ORDER_COUNTRIES = {
  ...COUNTRIES,
  OTHERS: '其他',
  CN: '转寄国内',
};
