import _logo from './logo.svg';
import _logoFull from './logo_full.svg';
import _Contraband from './Contraband';
import _Insurance from './Insurance';

export const LOGO = _logo;
export const LOGO_FULL = _logoFull;
export const Contraband = _Contraband;
export const Insurance = _Insurance;

export { SHIPPING_SERVICES } from './shipping_services';

export const TELEGRAM_BOT_API_KEY = '1890335896:AAGfwSXzSkQfuWqu8v4TRPkN3JVpfVY4Jjw';
export const TELEGRAM_BOT_CHAT_ID = -540316518;
export const TELEGRAM_BOT_URI = `https://api.telegram.org/bot${TELEGRAM_BOT_API_KEY}/sendMessage`;

export const COMPANY_FULL_NAME = '广州国际物流集运';
export const COMPANY_SHORT_NAME = '广州国际物流集运';
export const COMPANY_WECHAT_ID = 'Flasky526';
export const COMPANY_WORKING_HOURS = '周一至周五+周日 9:00-24:00，周六休息';
export const COMPANY_PACKING_DAYS_GUARANTEE = 2;
export const WAREHOUSE_ADDRESS = '广州市白云区鹤龙街道细彭岭路19号';
export const WAREHOUSE_PHONE = '18680269836';
export const WAREHOUSE_ZIP_CODE = '510440';

export const COUNTRIES_SUPPORTED = {
  US: '美国',
  GB: '英国',
  FR: '法国',
  DE: '德国',
  CA: '加拿大',
  AU: '澳大利亚',
  NZ: '新西兰',
};

export const CURRENCIES_SUPPORTED = {
  CNY: '人民币',
};

type PaymentInfoType = {
  show: boolean,
  alipay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  wechatPay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  paypal?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
};

export const PAYMENT_INFO: PaymentInfoType = {
  show: false,
};

export const ADMIN_LOGIN_PAGE_SHOW_LOGO = false;
export const ADMIN_LOGIN_PAGE_SHOW_COMPANY_NAME = true;
export const TICKET_SYSTEM_ENABLED = false;
export const SHOW_INSURANCE_PAGE = false;
export const EMAIL_NOTIFICATION_ENABLED = false;
export const ORDER_UPLOAD_IMAGE = false;
export const PACKAGE_UPLOAD_IMAGE = false;
export const DIRECT_ORDER_SELECT_SERVICE_ENABLED = true;
export const SHOW_SHIPPING_ROUTE_PAGE = true;
export const SHOW_CURRENCY_OPTION_IN_ORDER_PAGE = false;

export const PRICE_PER_PACKAGE = 1;
